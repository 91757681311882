import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import _ from 'lodash'
import Moment from 'moment'
import SecureLS from 'secure-ls'
import AxiosRequest from "../utils/axios-request"

# FILTERS
import toCurrency from '../filters/to_currency'
# METHODS
import promotionalCode from '../methods/index_cart/promotional_code'
import promotionalCodeRemove from '../methods/index_cart/promotional_code_remove'
import addNewCant from '../methods/index_cart/add_new_cant'
import removeNewCant from '../methods/index_cart/remove_new_cant'
import removeCart from '../methods/index_cart/remove_cart'
import checkout from '../methods/index_cart/checkout'

Vue.use TurbolinksAdapter

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'index_cart'

  return false unless el?

  new Vue {
    el
    filters: {toCurrency}
    methods: {
      promotionalCode,
      addNewCant,
      removeNewCant,
      removeCart,
      checkout,
      promotionalCodeRemove
      calcule_delivery: (cart_items, dev, security_key)->
        if JSON.parse dev
          router_api = 'http://dev.fenixrepuestos.com.co:3000'
        else
          router_api = 'https://api.fenixrepuestos.com.co'
        authenticity_token = $('[name="csrf-token"]')[0].content

        final_data = {
          cart_items,
          authenticity_token,
          security_key
        }

        request = await $.ajax(
          {
            url: "#{router_api}/api/v1/shipping_cost",
            data: JSON.stringify(final_data),
            method: 'post',
            processData: false
            contentType: "application/json"
          }
        )
        @total_delivery += parseInt request.cost
    }
    data: -> {
      idshopping_cart: undefined
      cart_items: {},
      total_delivery: 0
      discount: {
        discount: 0,
        ptg: 0
      }
      waiting_cart: false
    }
    mounted: ->
      # Acceso a secure local storage
      ls = new SecureLS()
      cart = ls.get 'cart'
      disc = ls.get 'discount'
      @discount = disc if disc
      uid = document.getElementById('uid').dataset.uid
      dev = document.getElementById('dev').dataset.dev
      sk = document.getElementById('sk').dataset.sk
      final_cart = {}
      # Carrito que se llenó sin sesion
      final_cart['joker'] = cart['joker'] if cart['joker']
      # Comprobar si hay usuario
      if uid
        final_cart[uid] = cart[uid]
        # joker_cart
        query = $.get "/customers/#{uid}/shopping_carts/check.json"
        query.done (res) =>
          {cart_items, status, discount, idshopping_cart} = res
          @idshopping_cart = idshopping_cart
          switch status
            when 'waiting' then @waiting_cart = true
            when 'paid' then final_cart = {}
            when 'active','expired','declined'
              # carrito ls
              local_cart = _.map final_cart[uid], (elem) => {elem...}
              # carrito bd
              remote_cart = _.map cart_items, (elem) => {elem...}
              # join in the carts
              final_cart[uid] = _.unionBy local_cart, remote_cart, (elem) =>
                validation = []
                if elem.type == 15
                  validation.push elem.service_id, Moment(elem.date).format('MM DD YYYY'), Moment(elem.time_select).format('h:mm A')
                else if elem.type == 16
                  validation.push elem.product_id
                  validation.push elem.options.product_inventory_id if elem.options.product_inventory_id
                  validation.push elem.address_id
                  validation.push elem.store_id
                validation.join()

              # joker_cart
              if final_cart['joker']
                for item in final_cart[uid]
                  somecart = final_cart['joker'].some((ite_jok) =>
                    if ite_jok.type == 15
                      (ite_jok.service_id == item.service_id && Moment(ite_jok.date).format('MM DD YYYY') == Moment(item.date).format('MM DD YYYY') &&  Moment(ite_jok.time_select).format('h:mm A') == Moment(item.time_select).format('h:mm A') && ite_jok.store_id == item.store_id)
                    else if ite_jok.type == 16
                      if ite_jok.options
                        (ite_jok.product_id == item.product_id && ite_jok.options.product_inventory_id == item.options.product_inventory_id && ite_jok.address_id == item.address_id && ite_jok.store_id == item.store_id)
                      else
                        (ite_jok.product_id == item.product_id && ite_jok.address_id == item.address_id && ite_jok.store_id == item.store_id)
                  )

                  if somecart
                    index = final_cart['joker'].findIndex((item_joker) =>
                      if item_joker.type == 15
                        (item_joker.service_id == item.service_id && Moment(item_joker.date).format('MM DD YYYY') == Moment(item.date).format('MM DD YYYY') &&  Moment(item_joker.time_select).format('h:mm A') == Moment(item.time_select).format('h:mm A') && item_joker.store_id == item.store_id)
                      else if item_joker.type == 16
                        if item_joker.options
                          (item_joker.product_id == item.product_id && item_joker.options.product_inventory_id == item.options.product_inventory_id && item_joker.address_id == item.address_id && item_joker.store_id == item.store_id)
                        else
                          (item_joker.product_id == item.product_id && item_joker.address_id == item.address_id && item_joker.store_id == item.store_id)
                    )
                    final_cart['joker'].splice index, 1
                    ls.set 'cart', final_cart
          @discount = discount if discount
        # final cart-joker
        if final_cart[uid] && final_cart['joker']
          for item in final_cart[uid]
            somecart = final_cart['joker'].some((item_joker) =>
              if item_joker.options
                (item_joker.product_id == item.product_id && item_joker.options.product_inventory_id == item.options.product_inventory_id && item_joker.address_id == item.address_id && item_joker.store_id == item.store_id)
              else
                (item_joker.product_id == item.product_id && item_joker.address_id == item.address_id && item_joker.store_id == item.store_id)
            )
            if somecart
              index = final_cart['joker'].findIndex((item_joker) =>
                if item_joker.options
                  (item_joker.product_id == item.product_id && item_joker.options.product_inventory_id == item.options.product_inventory_id)
                else
                  (item_joker.product_id == item.product_id)
              )
              final_cart['joker'].splice index, 1
              ls.set 'cart', final_cart
      @cart_items = final_cart
      @calcule_delivery(@cart_items, dev, sk)
    computed: {
      total_sumary: ->
        {cart_items} = @
        return 0 if Object.keys(cart_items).length == 0
        uid = document.getElementById('uid').dataset.uid
        joined_cart = []
        if cart_items['joker']
          joined_cart.push(cart_items['joker']...) if cart_items['joker'].length > 0
        if @cart_items[uid]
          joined_cart.push(cart_items[uid]...) if uid
        acum = 0
        for item in joined_cart
          if item.price > 0
            if item.type == 16
              acum += parseInt item.price * item.quantity
            else if item.type == 15
              acum += parseInt item.price
          else
            acum
        acum
    }
    destroyed: ->
      @cart_items = []
  }
