import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls'

export default () ->
  Swal.fire({
    title: 'Estas seguro'
    icon: 'warning'
    showCancelButton: true
    confirmButtonText: 'Si, quitar código!'
    cancelButtonText: 'No'
  }).then (result) =>
    if result.isConfirmed
      ls = new SecureLS()
      ls.remove('discount')
      if !@idshopping_cart
        Swal.fire({
          title: 'Has removido el código promocional',
          icon: 'success'
          timer: 1800
          showConfirmButton: false
        }).then ->
          location.reload()
      else
        AxiosRequest('/promotional_code_remove', {cart: @idshopping_cart}, 'post').then ({data}) =>
          Swal.fire({
            title: data.message,
            icon: 'success'
            timer: 1900
            showConfirmButton: false
          }).then ->
            location.reload()
