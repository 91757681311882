import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls'

export default (index, uid = 'joker', item) ->
  #Sweet alert nivel 1
  the_sweet_alert = Swal.fire {
    title: '¿Está seguro?'
    text: 'Tendrá que volver a agregar el producto'
    icon: 'warning'
    showCancelButton: true
    inputAttributes:
      autocapitalize: 'off'
    customClass:
      confirmButton: 'uk-button uk-button-primary uk-margin-small-right'
      cancelButton: 'uk-button uk-button-secondary'
    buttonsStyling: false
    confirmButtonText: 'Si eliminar producto'
    cancelButtonText: 'No cancelar'
  }
  #Gestion de sweet alert
  the_sweet_alert.then (result) =>
    if result.value
      ls = new SecureLS()
      remove_item = (message) =>
        if item.type == 15
          swal_title = 'Servicio eliminado'
        else
          swal_title = 'producto eliminado'
        Swal.fire swal_title, message, 'success'
        actual_cart[uid].splice index, 1
        @cart_items = actual_cart
        ls.set 'cart', actual_cart
      actual_cart = @cart_items

      # Borrar el item del carrito en backend cuando no es joker
      if uid isnt 'joker' and item.backened
        query = AxiosRequest("/customers/shopping_carts/remove", { idproduct_in_cart: item.idproduct_in_cart}, 'post').then ({data})->
          remove_item(data.message)
      else
        if item.type == 15
          remove_item('Has removido el servicio de tu carrito')
        else
          remove_item('Has sacado el producto de tu carrito')
