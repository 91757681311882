import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls'

export default (index, uid = 'joker', item) ->
  ls = new SecureLS();
  actual_cart = ls.get 'cart'
  if !actual_cart
    actual_cart = @cart_items
  # buscamos el carrito al que sele va hacer el proceso
  cart_filter = actual_cart[uid].filter((e) =>
    if e.options
      (e.product_id == item.product_id && e.options.product_inventory_id == item.options.product_inventory_id && e.address_id == item.address_id && e.store_id == item.store_id)
    else
      (e.product_id == item.product_id && e.address_id == item.address_id && e.store_id == item.store_id)
  )
  for cart_item in cart_filter
    if cart_item.quantity <= 1
      Swal.fire({
        title: 'Error',
        text: "las unidades no pueden ser zero",
        icon: 'error',
      })
    else
      cart_item.quantity--
  @cart_items = actual_cart
  ls.set 'cart', actual_cart
